import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  AllFaqItemDescriptors,
  FaqItemDescriptor,
  FaqPage,
  FrontFAQMessages,
} from './faqPageEnums';
import {
  ShIntlMessageDefinition,
  ShText,
  ShTextHTML,
  useShTranslate,
} from '@shoootin/translations';
import AppSection from '../../primitives/appSection';
import { ShHr, ShSvgArrowNext, ShTitle } from '@shoootin/components-web';
import { ShEasings } from '@shoootin/design-tokens';

type FaqItem = {
  title: string;
  titleId: ShIntlMessageDefinition;
  description: string;
  descriptionId: ShIntlMessageDefinition;
  page: FaqPage[];
};

const FaqElement = ({ item }: { item: FaqItem }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <div css={{ marginBottom: 20 }}>
      <div
        onClick={toggleOpen}
        css={{
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',
          gap: 15,
        }}
      >
        <div
          style={{
            rotate: `${isOpen ? -90 : 90}deg`,
            transition: `rotate .2s ${ShEasings.easeOut}`,
          }}
        >
          <ShSvgArrowNext width={20} color={'black'} />
        </div>
        <ShTitle size={5}>
          <ShText message={item.titleId} />
        </ShTitle>
      </div>
      <div
        ref={contentRef}
        style={{
          height,
          overflow: 'hidden',
          transition: `height .2s ${ShEasings.easeOut}`,
        }}
      >
        <div
          style={{
            paddingLeft: 35,
            paddingTop: 20,
            paddingBottom: 10,
          }}
        >
          <ShTextHTML message={item.descriptionId} />
        </div>
      </div>
      <ShHr mini />
    </div>
  );
};

// Translate the items, so that they can be used by the search engine!
const useFaqItems = (itemDescriptors: FaqItemDescriptor[]): FaqItem[] => {
  const translate = useShTranslate();
  return useMemo(() => {
    return itemDescriptors.map((descriptor) => ({
      title: translate(descriptor.messages.title),
      titleId: descriptor.messages.title,
      description: translate(descriptor.messages.description),
      descriptionId: descriptor.messages.description,
      page: descriptor.page,
    }));
  }, [itemDescriptors, translate]);
};

// group items by tab and category
type FaqItemAggregate = FaqItem[];
const useFaqItemAggregate = (
  page: FaqPage,
  faqItems: FaqItem[],
): FaqItemAggregate =>
  useMemo(() => {
    return faqItems.filter((item) => item.page.includes(page));
  }, [faqItems]);

const MiniFaq = ({ page }: { page: FaqPage }) => {
  const homeItems = useFaqItems(
    AllFaqItemDescriptors.filter((item) => item.page.includes(page)),
  );

  // const homeItems = useFaqItemAggregate(page, faqItems);

  return (
    <AppSection
      className="faq gradient-tt"
      header={
        <span>
          <ShTitle size={2}>
            <ShText message={FrontFAQMessages.title} />
          </ShTitle>
          <p>
            <ShText message={FrontFAQMessages.subtitle} />
          </p>
        </span>
      }
    >
      <div css={{ marginTop: 40 }}>
        {homeItems.map((categorySection) => (
          <FaqElement key={categorySection.title} item={categorySection} />
        ))}
      </div>
    </AppSection>
  );
};

export default MiniFaq;
