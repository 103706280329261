import React, { useState } from 'react';
import { MainLayout } from '../../layout/mainLayout';

import { H2 } from '../../primitives/appTitle';
import './frontHome.less';
import HeroSlider from '../../components/hero/heroSlider';
import AppSection from 'primitives/appSection';
import { AppInternalLink, AppInternalPaths } from '../../primitives/appLink';

import CoverMetiers from '../../components/coverMetiers';
import HomeHighlights from './homeHighlightsSection';
import { ShText } from '@shoootin/translations';
import { FrontHomeMessages } from './homePageTranslations';
import HomePageCards from './homePageCards';
import { FrontCommonMessages } from '../../appCommonTranslations';
import HomeTabsetProfessionTabSet from './homePageProfessionTabSet';
import { ProfessionGalleryModal } from '../../components/gallery/galleryModal';
import { ProfessionShootingType } from '../../appConstants';
import { HomeWorldMapSvg } from './homePageWorldMap';
import { TheyTrustUsHomePageSection } from '../../components/theyTrustUsSection';
import MiniFaq from '../../components/faq/miniFaq';

const HomePageUsecasesSection = () => (
  <AppSection
    className="home-usecases"
    header={
      <H2>
        <ShText message={FrontHomeMessages.professionTitle} />
      </H2>
    }
  >
    <HomeTabsetProfessionTabSet />
  </AppSection>
);

const HomePageOtherProfessionsSection = () => {
  const [selectedProfession, setSelectedProfession] =
    useState<ProfessionShootingType>();

  return (
    <>
      <AppSection className="left">
        <CoverMetiers
          professions={[
            'residentialRealEstate',
            'commercialRealEstate',
            'architect',
            'hotel',
            'restaurant',
            'retail',
          ]}
          cta={{
            label: FrontCommonMessages.seeGallery,
            onCTAPress: setSelectedProfession,
          }}
        />
      </AppSection>
      <ProfessionGalleryModal
        selectedProfession={selectedProfession}
        onClose={() => setSelectedProfession(undefined)}
      />
    </>
  );
};

const HomePageZonesSection = () => (
  <AppSection
    className="home-zones"
    header={
      <div>
        <H2>
          <ShText message={FrontHomeMessages.zonesTitle} />
        </H2>
        <p>
          <ShText message={FrontHomeMessages.zonesDescription} />
        </p>
        <p>
          <AppInternalLink to={AppInternalPaths.about} className="link">
            <ShText message={FrontHomeMessages.zonesMore} />
          </AppInternalLink>
        </p>
      </div>
    }
  >
    <HomeWorldMapSvg />
  </AppSection>
);

const HomePageHowItWorksSection = () => (
  <AppSection
    className="home-plan darker"
    header={
      <div>
        <H2>
          <ShText message={FrontHomeMessages.howItWorksTitle} />
        </H2>
        <p>
          <ShText message={FrontHomeMessages.howItWorksDescription} />
        </p>
      </div>
    }
  >
    <HomePageCards />
  </AppSection>
);

const HomePage = () => {
  return (
    <MainLayout className="page-home" pageName="home">
      <HeroSlider />
      <HomeHighlights />
      <TheyTrustUsHomePageSection />
      <HomePageUsecasesSection />
      <HomePageOtherProfessionsSection />
      <HomePageZonesSection />
      <HomePageHowItWorksSection />
      <MiniFaq page={'HOME'} />
    </MainLayout>
  );
};

export default HomePage;
